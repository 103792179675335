import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import tw, { styled } from 'twin.macro'
import axios from 'axios'

import { ReactComponent as CloseIcon } from '@/assets/images/close-icon.svg'
import { ReactComponent as ErrorIcon } from '@/assets/images/icon-alert-red.svg'
import { ReactComponent as EthIcon } from '@/assets/images/icon-eth-blue.svg'
import { ReactComponent as OpIcon } from '@/assets/images/icon-op.svg'
import { ReactComponent as MoveIcon } from '@/assets/images/icon-move.svg'
import { ReactComponent as ReturnIcon } from '@/assets/images/icon-return.svg'
import { ReactComponent as ArrowRightIcon } from '@/assets/images/icon-arrow-right.svg'
import { ReactComponent as ChevronRightIcon } from '@/assets/images/icon-chevron-right.svg'
import { ReactComponent as CompletedIcon } from '@/assets/images/icon-completed.svg'
import { ClipboardCopy, Spinner } from '@/components/shared'

import { ethers } from 'ethers'
import { formatEther } from 'ethers/lib/utils'

export default function Stake() {
  const navigate = useNavigate()

  // const { isConnected, isConnecting, isReconnecting } = useConnect()
  const [isPending, setIsPending] = useState<boolean>(false)
  const [isCompleted, setIsCompleted] = useState<boolean>(false)
  const [isTxInfoOpen, setIsTxInfoOpen] = useState<boolean>(false)
  const [isValidAddress, setIsValidAddress] = useState<boolean>(false)
  const [depositTx, setDepositTx] = useState<any>()
  const [withdrawTx, setWithdrawTx] = useState<any>()
  const [key, setKey] = useState<string>('')
  const [sepoliaTxUrl, setSepoliaTxUrl] = useState<string>()
  const [sepoliaOPTxUrl, setSepoliaOPTxUrl] = useState<string>()
  const [isMove, setIsMove] = useState<boolean>(false)
  const [balance, setBalance] = useState<number>(0)
  const [error, setError] = useState<string>('')

  useEffect(() => {
    if (key.length > 0) setIsValidAddress(ethers.utils.isAddress(key))
    else setIsValidAddress(false)
  }, [key])

  useEffect(() => {
    if (!depositTx) return
    if (!depositTx.optimismSepoliaResult) return
    if (!depositTx.optimismSepoliaResult.transaction) return
    if (!depositTx.optimismSepoliaResult.transaction.hash) return

    setSepoliaOPTxUrl(
      `https://sepolia-optimism.etherscan.io/tx/${depositTx.optimismSepoliaResult.transaction.hash}`
    )
  }, [depositTx])

  useEffect(() => {
    if (!depositTx) return
    if (!depositTx.sepoliaTransaction) return
    if (!depositTx.sepoliaTransaction.hash) return

    setSepoliaTxUrl(`https://sepolia.etherscan.io/tx/${depositTx.sepoliaTransaction.hash}`)
  }, [depositTx])

  useEffect(() => {
    if (!withdrawTx) return
    if (!withdrawTx.destinationResult) return
    if (!withdrawTx.destinationResult.transaction) return
    if (!withdrawTx.destinationResult.transaction.hash) return

    setSepoliaOPTxUrl(
      `https://sepolia-optimism.etherscan.io/tx/${withdrawTx.destinationResult.transaction.hash}`
    )
  }, [withdrawTx])

  useEffect(() => {
    if (!withdrawTx) return
    if (!withdrawTx.originResult) return
    if (!withdrawTx.originResult.transaction) return
    if (!withdrawTx.originResult.transaction.hash) return

    setSepoliaTxUrl(`https://sepolia.etherscan.io/tx/${withdrawTx.originResult.transaction.hash}`)
  }, [withdrawTx])

  const fetchBalance = async () => {
    const transferInfo: any = await axios.get(
      `https://i5lysaxqa0.execute-api.eu-central-1.amazonaws.com/opsep/get-redemption-balance?address=${key}`
    )

    const { balanceOfUser } = transferInfo.data
    setBalance(Number(formatEther(balanceOfUser)))
  }

  useEffect(() => {
    if (isValidAddress) fetchBalance()
  }, [key, isValidAddress])

  const handleChange = async (value: string) => {
    setKey(value)
  }

  const handleMove = async () => {
    setIsPending(true)
    setIsMove(true)
    try {
      const result = await axios.get(
        `https://arljw70axh.execute-api.eu-central-1.amazonaws.com/sep/claim-merc20-optimism-sepolia?recipient=${key}`
      )

      if (
        result.data.sepoliaTransaction &&
        result.data.optimismSepoliaResult &&
        result.data.optimismSepoliaResult.error
      ) {
        // Try and push destination through with a re-try on the destination part only
        console.warn('Re-trying mint on Optimism (2)')
        await new Promise((r) => setTimeout(r, 200))

        const verifyAndMintResult = await axios.get(
          `https://arljw70axh.execute-api.eu-central-1.amazonaws.com/sep/verify-and-mint?recipient=${key}`
        )

        setDepositTx({
          optimismSepoliaResult: verifyAndMintResult.data,
          sepoliaTransaction: result.data.sepoliaTransaction
        })
      } else {
        setDepositTx(result.data)

        const unprocessedLeaves = result.data.optimismSepoliaResult.unprocessedUserLeaves
        if (unprocessedLeaves && unprocessedLeaves.length && unprocessedLeaves.length > 0) {
          // Clear off the destination by executing commits from origin
          console.log('Found some unprocessed kernel commit leaves', unprocessedLeaves)

          let delay = 1000.0
          const dispatchVerifyRequest = async (index: string, recipient: string, delay: number) => {
            await new Promise((r) => setTimeout(r, delay))
            console.log('Dispatched request to process index', index)
            axios.get(
              `https://arljw70axh.execute-api.eu-central-1.amazonaws.com/sep/verify-and-mint?indexOverride=${index}&recipient=${recipient}`
            )
          }

          for (let i = 0; i < unprocessedLeaves.length; i++) {
            dispatchVerifyRequest(unprocessedLeaves[i].index, key, delay)
            delay += 4000.0
          }
        }
      }

      setIsPending(false)
      setIsCompleted(true)
    } catch (e: any) {
      console.log(e)
      if (e.response.data) {
        setDepositTx(e.response.data)
        setError(e.response.data.error.msg)
      }
      setIsPending(false)
    }
  }

  const handleReturn = async () => {
    setIsPending(true)
    setIsMove(false)
    try {
      const result = await axios.post(
        `https://i5lysaxqa0.execute-api.eu-central-1.amazonaws.com/opsep/return-to-origin`,
        {
          owner: key
        }
      )

      if (result.data.originResult && result.data.originResult.error) {
        console.log('Return to origin did not fully complete. Retrying the second part again')
        const verifyAndMintResult = await axios.get(
          `https://i5lysaxqa0.execute-api.eu-central-1.amazonaws.com/opsep/verify-and-mint?recipient=${key}`
        )

        setWithdrawTx({
          destinationResult: result.data.destinationResult,
          originResult: verifyAndMintResult.data
        })
      } else {
        setWithdrawTx(result.data)

        // Since actual balance processed could be more than the balance of user, show what was processed (Scenario: balance is currently zero but previously a commit was done)
        setBalance(Number(formatEther(result.data.totalBalanceProcessed)))

        const unprocessedLeaves = result.data.unprocessedUserLeaves
        if (unprocessedLeaves && unprocessedLeaves.length && unprocessedLeaves.length > 0) {
          // Clear off the origin by executing commits from destination
          console.log('Found some unprocessed kernel commit leaves on return', unprocessedLeaves)

          let delay = 1000.0
          const dispatchVerifyRequest = async (index: string, recipient: string, delay: number) => {
            await new Promise((r) => setTimeout(r, delay))
            console.log('Dispatched request to process index opsepolia', index)
            axios.get(
              `https://i5lysaxqa0.execute-api.eu-central-1.amazonaws.com/opsep/verify-and-mint?indexOverride=${index}&recipient=${recipient}`
            )
          }

          for (let i = 0; i < unprocessedLeaves.length; i++) {
            if (
              result.data.originResult.commitmentFromOrigin.accumulatorCommitLeafIndex !==
              unprocessedLeaves[i].index.toString()
            ) {
              dispatchVerifyRequest(unprocessedLeaves[i].index, key, delay)
            }
            delay += 5000.0
          }
        }
      }
      setIsPending(false)
      setIsCompleted(true)
    } catch (e: any) {
      setIsPending(false)
      console.log(e)

      if (e.response.data) {
        setWithdrawTx(e.response.data)
        setError(e.response.data.error.msg)
      }
    }
  }

  const handleClose = () => {
    setBalance(0)
    setIsCompleted(false)
    setIsMove(false)
    setSepoliaTxUrl('#')
    setSepoliaOPTxUrl('#')
    setDepositTx(undefined)
    setWithdrawTx(undefined)
    setError('')
    fetchBalance()
  }

  return (
    <div className="text-white flex flex-col gap-8 mt-14 px-4 md:px-0">
      <div className="flex flex-col gap-10">
        <div className="w-full text-2xl md:text-5xl font-semibold flex items-center justify-center">
          Multichain Atomic Assets
        </div>
        <div className="w-full md:text-lg flex flex-col gap-2 md:gap-0 md:flex-row items-center justify-center">
          Move assets between
          <div className="flex items-center">
            <IconWrapper>
              <EthIcon /> ETH
            </IconWrapper>
            and
            <IconWrapper>
              <OpIcon /> OP
            </IconWrapper>
          </div>
          (Sepolia Testnet) without bridges
        </div>
      </div>
      {error && error.length > 0 ? (
        <div className="max-w-xl mx-auto w-full relative flex flex-col items-center gap-4">
          <Card>
            <ErrorIcon />
            <div className="font-semibold mt-3">Transaction Error</div>
            <div className="flex items-center justify-center gap-2 mt-3 w-full max-w-xs">
              {error}
            </div>

            <div className="flex items-center max-w-xs w-full">
              <Button onClick={handleClose}>Close</Button>
            </div>
          </Card>

          <TxDetailCard>
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => setIsTxInfoOpen(!isTxInfoOpen)}>
              Transaction Details
              <ChevronRightIcon />
            </div>
            {isTxInfoOpen && (
              <div className="break-words text-xs">
                {JSON.stringify(isMove ? depositTx : withdrawTx)}
              </div>
            )}
          </TxDetailCard>
        </div>
      ) : isCompleted ? (
        <div className="max-w-xl mx-auto w-full relative flex flex-col items-center gap-4">
          <Card>
            <CompletedIcon />
            <div className="font-semibold mt-3">Transaction Completed</div>
            <div className="flex items-center justify-center gap-2 border-b w-full max-w-xs pb-3 border-border">
              {isMove ? (
                <>
                  <EthIcon width={16} /> 5.0 ETH <ArrowRightIcon />
                  <OpIcon width={16} /> 5.0 OP
                </>
              ) : (
                <>
                  <OpIcon width={16} />{' '}
                  {balance.toLocaleString(undefined, { minimumFractionDigits: 1 })} OP{' '}
                  <ArrowRightIcon />
                  <EthIcon width={16} />{' '}
                  {balance.toLocaleString(undefined, { minimumFractionDigits: 1 })} ETH
                </>
              )}
            </div>
            <div className="flex items-center max-w-xs w-full justify-between">
              {isMove ? (
                <>
                  <a
                    href={sepoliaTxUrl}
                    target="_blank"
                    className="text-secondary font-semibold"
                    rel="noreferrer">
                    View on Etherscan
                  </a>
                  <a
                    href={sepoliaOPTxUrl}
                    target="_blank"
                    className="text-secondary font-semibold"
                    rel="noreferrer">
                    View on Optimism
                  </a>
                </>
              ) : (
                <>
                  <a
                    href={sepoliaOPTxUrl}
                    target="_blank"
                    className="text-secondary font-semibold"
                    rel="noreferrer">
                    View on Optimism
                  </a>
                  <a
                    href={sepoliaTxUrl}
                    target="_blank"
                    className="text-secondary font-semibold"
                    rel="noreferrer">
                    View on Etherscan
                  </a>
                </>
              )}
            </div>
            <div className="flex items-center max-w-xs w-full">
              <Button onClick={handleClose}>Close</Button>
            </div>
          </Card>

          <TxDetailCard>
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => setIsTxInfoOpen(!isTxInfoOpen)}>
              Transaction Details
              <ChevronRightIcon />
            </div>
            {isTxInfoOpen && (
              <div className="break-words text-xs">
                {JSON.stringify(isMove ? depositTx : withdrawTx)}
              </div>
            )}
          </TxDetailCard>
        </div>
      ) : isPending ? (
        <div className="max-w-xl mx-auto w-full relative flex items-center">
          <Card>
            <Spinner />
            <div className="font-semibold mt-3">Transaction in progress</div>
            <div className="flex items-center gap-2">
              {isMove ? (
                <>
                  <EthIcon width={16} /> 5.0 ETH <ArrowRightIcon />
                  <OpIcon width={16} /> 5.0 OP
                </>
              ) : (
                <>
                  <OpIcon width={16} />{' '}
                  {balance.toLocaleString(undefined, { minimumFractionDigits: 1 })} OP{' '}
                  <ArrowRightIcon />
                  <EthIcon width={16} />{' '}
                  {balance.toLocaleString(undefined, { minimumFractionDigits: 1 })} ETH
                </>
              )}
            </div>
          </Card>
        </div>
      ) : (
        <>
          <div className="max-w-xl mx-auto w-full relative flex items-center mt-8">
            <Input
              value={key}
              onChange={(e) => handleChange(e.target.value)}
              placeholder="Wallet address"
            />

            {key.length > 0 && (
              <>
                <div className="cursor-pointer absolute right-3" onClick={() => handleChange('')}>
                  <CloseIcon />
                </div>
                <div className="cursor-pointer absolute right-10">
                  <ClipboardCopy size={24} copyText={key} />
                </div>
              </>
            )}
          </div>
          <div className="flex max-w-md mx-auto w-full items-center gap-3">
            <Button disabled={!isValidAddress || isPending} onClick={handleMove}>
              Move Assets <MoveIcon />
            </Button>
            <Button
              disabled={!isValidAddress || isPending}
              onClick={handleReturn}
              tw="bg-opacity-20">
              Return Assets <ReturnIcon />
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

const Card = styled.div`
  ${tw`bg-[#222] px-6 py-10 flex flex-col w-full items-center gap-3 text-white`}
`
const IconWrapper = styled.div`
  ${tw`rounded-full mx-2 md:text-xl font-medium pl-1 py-0.5 pr-3 flex items-center gap-2 bg-white bg-opacity-10`}
`
const Input = styled.input`
  ${tw`w-full text-grey25 bg-grey50 outline-none py-3 pr-20 pl-3 border-b border-grey500 text-lg md:text-[32px]`}
`
const Button = styled.button`
  ${tw`w-full rounded-full bg-[#E52887] py-2 flex items-center justify-center gap-3`}
  &:disabled {
    ${tw`bg-grey500 text-grey600`}
  }
`

const TxDetailCard = styled.div`
  ${tw`bg-[#222] p-6 flex flex-col relative w-full text-grey600 gap-5 mb-5`}
`
