import './App.css'

import { Buffer } from 'buffer'

import { IntercomProvider } from 'react-use-intercom'

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import { LayoutDashboard } from '@/components/layouts'

import { Transfer } from '@/views'

if (!window.Buffer) {
  window.Buffer = Buffer
}

function App() {
  return (
    <IntercomProvider appId="xg5qffph" apiBase="https://api-iam.intercom.io" autoBoot>
      <Router>
        <Routes>
          <Route path="/" element={<LayoutDashboard />}>
            <Route index element={<Transfer />} />
          </Route>
        </Routes>
      </Router>
    </IntercomProvider>
  )
}

export default App
