import './styles.scss'

import { FC } from 'react'

import { Outlet } from 'react-router-dom'

import { Topbar } from './components'

const DashboardLayout: FC = () => {
  return (
    <div className="layout">
      <Topbar />
      <Outlet />
    </div>
  )
}

export default DashboardLayout
