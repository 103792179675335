import '../styles.scss'

import { FC } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ReactComponent as Logo } from '@/assets/images/logo.svg'

const Topbar: FC = () => {
  const navigate = useNavigate()

  const { pathname } = useLocation()

  return (
    <div className="topbar">
      <a onClick={() => navigate('/')} rel={'noopener noreferrer'}>
        <Logo />
      </a>
    </div>
  )
}

export default Topbar
